
  <h2 class="center-header">My Bonsai Book - Features</h2>

  <nav class="center-nav">
    <ul>
      <li class="horizontal-nav">
        <a
          (click)="featureSelected('garden-feature')"
          routerLink="garden-feature"
          >Gardens</a
        >
      </li>
      <li class="horizontal-nav">
        <a
          (click)="featureSelected('bonsai-feature')"
          routerLink="bonsai-feature"
          >Bonsai</a
        >
      </li>
      <li class="horizontal-nav">
        <a
          (click)="featureSelected('history-feature')"
          routerLink="history-feature"
          >History</a
        >
      </li>
      <li class="horizontal-nav">
        <a (click)="featureSelected('todo-feature')" routerLink="todo-feature"
          >Todo</a
        >
      </li>
      <li class="horizontal-nav">
        <a
          (click)="featureSelected('gallery-feature')"
          routerLink="gallery-feature"
          >Gallery</a
        >
      </li>
      <li class="horizontal-nav">
        <a routerLink="recyclebin-feature">Recycle Bin</a>
      </li>
    </ul>

    <select
      class="select-option"
      #featureSelect
      (change)="onOptionsSelected(featureSelect.value)"
    >
      <option class="option" value="garden-feature">Gardens</option>
      <option class="option" value="bonsai-feature">Bonsai</option>
      <option class="option" value="history-feature">History</option>
      <option class="option" value="todo-feature">Todo</option>
      <option class="option" value="gallery-feature">Gallery</option>
      <option class="option" value="recyclebin-feature">Recycle Bin</option>
    </select>
  </nav>


  <div class="features-area">
    <div class="col-md-8 offset-md-2">
      <div class="content-area">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
