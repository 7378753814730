<h3 class="center-header">Images</h3>
<p>
  Bonsai can have photos added to them.
</p>
<p>
  You can add photos from the <strong>IMAGES</strong> tab from the Bonsai page,
  or from the <strong>Edit History</strong>, and
  <strong>Edit Todo</strong> screens.
</p>
<p>
  When viewing a photo, you can add a caption and set it as your Bonsai's
  profile image.
</p>
<p>
  The <strong>Home</strong> screen in the appalso has a Gallery page. All your
  images are presented on this global page.
</p>
<hr />
<sub>
  *Note the History and Todo pages for a Garden, doesn't provide the option to
  take photos, this is only possible on Bonsai!
</sub>
<div style="height: 100px;"></div>
