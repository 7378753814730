import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-garden-feature',
  templateUrl: './garden-feature.component.html',
  styleUrls: ['./garden-feature.component.scss']
})
export class GardenFeatureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
