import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
//https://medium.com/@samuelhenshaw2020/recaptcha-v2-in-angular-8-with-back-end-verification-with-nodejs-9574f297fdef
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';


// import Amplify from 'aws-amplify';
// import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
// import awsconfig from '../aws-exports';
//awsconfig.oauth.domain = "auth.mybonsaibook.com";
// Amplify.configure(awsconfig);

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { MybonsaiComponent } from './mybonsai/mybonsai.component';
import { FeaturesComponent } from './features/features.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { GardenFeatureComponent } from './components/garden-feature/garden-feature.component';
import { BonsaiFeatureComponent } from './components/bonsai-feature/bonsai-feature.component';
import { HistoryFeatureComponent } from './components/history-feature/history-feature.component';
import { TodoFeatureComponent } from './components/todo-feature/todo-feature.component';
import { GalleryFeatureComponent } from './components/gallery-feature/gallery-feature.component';
import { RecyclebinFeatureComponent } from './components/recyclebin-feature/recyclebin-feature.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [    
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    MybonsaiComponent,
    FeaturesComponent,
    NotfoundComponent,
    GardenFeatureComponent,
    BonsaiFeatureComponent,
    HistoryFeatureComponent,
    TodoFeatureComponent ,
    GalleryFeatureComponent,
    RecyclebinFeatureComponent,
    TermsComponent,
    PrivacyComponent
  ],
  imports: [    
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    // AmplifyUIAngularModule
  ],  
  providers: [],//AmplifyService
  bootstrap: [AppComponent]
})
export class AppModule { }
