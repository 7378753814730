import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
//uncomment below and fields in app.module.ts lines 11-15 & 57
// import { Auth } from 'aws-amplify';
// import {
//   onAuthUIStateChange,
//   CognitoUserInterface,
//   AuthState,
// } from '@aws-amplify/ui-components';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  title: 'sign-in';
  user: undefined;//remove when re-enabling aws
  authState: undefined;//remove when re-enabling aws
  // user: CognitoUserInterface | undefined;
  // authState: AuthState;
  
  constructor(private ref: ChangeDetectorRef) {
    //  this.formFields = [
    //   {
    //     type: "email",
    //     label: "Email",
    //     placeholder: "Enter you Email",
    //     required: true,
    //   },
    //   {
    //     type: "password",
    //     label: "Password",
    //     placeholder: "Enter your Password",
    //     required: true,
    //   }]
  }

  ngOnInit() {
    // onAuthUIStateChange((authState, authData) => {
    //   this.authState = authState;
    //   this.user = authData as CognitoUserInterface;
    //   this.ref.detectChanges();
    // });
  }

  ngOnDestroy() {
    // return onAuthUIStateChange;
  }

  onUserClick() {}

  onLoginClick() {
    // Auth.federatedSignIn().then((iCreds) => {
    //   console.log(iCreds);
    // });
  }
}
