<h2 class="center-header">My Bonsai Book</h2>
<p></p>
<div class="center-header col-md-6 offset-md-3 text-content">
  <h6>
    <br /><br />
    My Bonsai Book is an app that allows bonsai enthusiasts to record and maintain their trees.

  </h6>
  <br /><br />
  <h6>
    
  </h6>
</div>
<div class="center-header col-md-6 offset-md-3">
  
  <div class="center-div" >
  <a href='https://play.google.com/store/apps/details?id=com.mybonsaibook&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
    <img class="center-image" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
  </a>
  Try it for free on Google Play. <br />
  <!-- And working on the iOS app. -->
</div>
  <br />
  <a class="nav-link" routerLink="/terms">Terms and Conditions</a>
  <a class="nav-link" routerLink="/privacy">Privacy Statement</a>
  <br />
</div>
