import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mybonsai',
  templateUrl: './mybonsai.component.html',
  styleUrls: ['./mybonsai.component.scss']
})
export class MybonsaiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
