import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private http: HttpClient
  ) { }

  sendToken(token) {
    var recaptchaUrl = "https://x6f7giuxvh.execute-api.ap-southeast-2.amazonaws.com/dev/resolvetoken";
    return this.http.post<any>(recaptchaUrl, token)
  }

  submitMessageForm(form){
    var formUrl = "https://4wcmik8op5.execute-api.ap-southeast-2.amazonaws.com/dev/contact";
    return this.http.post<any>(formUrl, form);
  }

}
