<h3 class="center-header">Bonsai</h3>
<p>
  This section is where you create the bonsai that you want to manage in My
  Bonsai Book.
</p>

You can embelish your Bonsai with details like: Height, Latin names, Style,
Start-date for age, and even mention how you started the Bonsai.
<p>
  Bonsai can have Profile images which helps you identify your trees, especially
  if you have a large Garden.
</p>

When you view your Bonsai in the App, you have 3 tabs for details:
<strong>HISTORY, TODOS, IMAGES</strong>.
<ul>
  <li>
    <p>
      The <strong>HISTORY</strong> tab show you the Records added to the Bonsai
      - including those Record added from any of the Gardens the Bonsai might be
      in.
    </p>
  </li>
  <li>
    <p>
      The <strong>TODOS</strong> tab shows a list of Todos that have been setup
      for the Bonsai.
    </p>
  </li>
  <li>
    <p>
      The <strong>IMAGES</strong> tab shows any photos that you may have taken
      for the Bonsai, including photos taken in Records or Todos.
    </p>
  </li>
</ul>
<hr />
<div style="height: 100px;"></div>
