import { Component, ElementRef, OnInit, NgModule } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
  })
export class FeaturesComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private elementRef : ElementRef) { }

  ngOnInit(): void {
  }

  onOptionsSelected(value:string){
    this.router.navigate([value], { relativeTo: this.route });
    console.log("the selected value is " + value);
  }

  featureSelected(value:String){
    //update drop-down with same value: 
    let domElement = this.elementRef.nativeElement.querySelector("Select");
    domElement.value = value;
    console.log("value:" + value);
  }

} 
