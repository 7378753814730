<div class="col-md-6 offset-md-3 text-content">
  <h2>Privacy Statement</h2>
  <br />
  We collect personal information from you, including information about your:
  Name, Last-name, Email address, Profile Photo (when signing in through Social
  media).
  <br /><br />
  We collect your personal information in order to identify you on our system.
  <br />
  If you choose not to enter an Email address, Name and Last-name, we'll not be
  able to create you an account.
  <br /><br />
  We keep your information safe by storing it on a secure server provided by
  Amazon Web Services and only allowing MyBonsaiBook administrators to have
  access to the data.
  <br /><br />
  We keep your information for the duration of your account's lifetime. Until
  you decide to Delete your Account, at which point we securely destroy it by
  deleting your details from our user database. You can Delete your Account from
  the Account page in the Mobile App.
  <br /><br />
  You have the right to ask for a copy of any personal information we hold about
  you, and to ask for it to be corrected if you think it is wrong. If you'd like
  to ask for a copy of your information, or to have it corrected, please contact
  us at admin@mybonsaibook.com.
  <br /><br />
  In addition to your personal information, we also store the content you create
  in the app.<br />
  This includes the data and photos you create when using My Bonsai Book.<br />
  This information will be used by us to improve our systems and services.
  <br />
  We do our best to keep your location secure.<br />
  GPS information found on your photos are removed before the photos are
  uploaded to the server. In some rare cases this process may not work as
  expected, and so we ask that if this is an issue for you,<br />
  please turn your camera's GPS settings off so that this will not become a
  problem in the future.

  <p></p>
  <hr />
  MyBonsaiBook<br />
  27-May-2021
</div>
