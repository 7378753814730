import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bonsai-feature',
  templateUrl: './bonsai-feature.component.html',
  styleUrls: ['./bonsai-feature.component.scss']
})
export class BonsaiFeatureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
