import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-todo-feature',
  templateUrl: './todo-feature.component.html',
  styleUrls: ['./todo-feature.component.scss']
})
export class TodoFeatureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
