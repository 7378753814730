<nav class="navbar navbar-expand-md bg-dark navbar-dark fixed-top">
  <a class="navbar-brand" href="home">My Bonsai Book</a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarCollapse"
    aria-controls="navbarCollapse"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/features">Features</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/contact">Contact</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/mybonsai">My Bonsai</a>
      </li>
    </ul>
<!-- 
    <form class="form-inline my-2 my-lg-0">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a
            class="nav-link"
            *ngIf="authState === 'signedin' && user"
            (click)="onUserClick()"
            >Hello, {{ user.username }}</a
          >
          <a
            class="nav-link"
            *ngIf="!authState || (authState === 'signin' && !user)"
            (click)="onLoginClick()"
            >Sign In</a
          >
        </li>
      </ul>
    </form> -->
  </div>
</nav>
