import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';
declare var grecaptcha: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {

  constructor(private appService: AppService) {}

  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;
  server = {
    message: "",
    error: ""
  };
  messageForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
    ]),
    message: new FormControl('', Validators.required),
    reCap: new FormControl()
  });

  get server_message(){
    return this.server.message;
  }

  get server_error(){
    return this.server.error;
  }

  get name() {
    return this.messageForm.get('name');
  }

  get email() {
    return this.messageForm.get('email');
  }

  get message() {
    return this.messageForm.get('message');
  }

  get reCap(){
    return this.messageForm.get('reCap');
  }

  getRecaptchaFormControl(){
    return this.messageForm.get('reCap');
  }


  setReCap(token: string){
    var val = this.getRecaptchaFormControl();
    val.setValue(token);    
  }  

  ngOnInit(): void {
    this.addRecaptchaScript();
  }

  buttonClicked(event){
    if(!this.reCap.value){
      this.server.error = "Please fill in reCapthca.";
      return;
    }
    this.server.error = this.server.message = "";

    const data =
    'name=' + encodeURIComponent(this.name.value) +
    '&email=' + encodeURIComponent(this.email.value) +
    '&message=' + encodeURIComponent(this.message.value) +
    '&reCap=' + encodeURIComponent(this.reCap.value);

    console.log(data);
    this.appService.submitMessageForm(data).subscribe(
      (data) => {
        this.server.message = data;
      },
      (error: HttpErrorResponse) => {
        if(error.error){
          var sMessage = "";
          for(var i in error.error)
            sMessage = !sMessage ? error.error[i] : sMessage + ", " + error.error[i];          
          this.server.error = "Errors received: " + sMessage;
        } else {
          this.server.error = error.message;
        }
        console.log(error);
      }
    )
  }

  addRecaptchaScript() {
 
    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }
   
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      fjs.setAttribute('name' ,'reCap');
      fjs.setAttribute('formControlName','reCap');      
      if (d.getElementById(id)) { obj.renderReCaptcha(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";      
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
   
  }

  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6Lf1P8QZAAAAACofcYc669FZbKVXqO5fnORmLWPh',
      'callback': async (response) => {
          console.log(`Resolved Response Token: ${response}`);
          this.setReCap(response);
      }
    });
  }
}
