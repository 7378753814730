<h2 class="center-header">Contact</h2>
<div class="box">
  <div>
    <div class="col-md-6 offset-md-3">
      <form id="messageFormId" [formGroup]="messageForm">
        <div layout-align="center center" layout="column">
          <div class="form-group">
            <label for="messageName">Name:</label>
            <input id="messageName" name="name" type="text" formControlName="name" class="form-control form-comp" required
              minlength="4" />
          </div>
          <div class="form-group">
            <label for="messageEmail">Email:</label>
            <input id="messageEmail" name="email" type="text" formControlName="email" class="form-control form-comp" required />
          </div>
          <div class="form-group">
            <label for="messageMessage">Message:</label>
            <textarea id="messageMessage" name="message" type="text" formControlName="message" class="form-control form-comp"
              required maxlength="2000"></textarea>
          </div>
          <div class="form-group">
            <div #recaptcha ></div>
          </div>
          <br />
          <div class="center-div">
            <button type="button" (click)="buttonClicked($event)" [disabled]="!messageForm.valid">Submit</button>
          </div>
        </div>
      </form>      
    </div>
  </div>
  <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger  trim-edge-padding form-comp">
    <div *ngIf="name.errors.required">
      Name is required.
    </div>
    <div *ngIf="name.errors.minlength">
      Name must be at least 4 characters long.
    </div>
  </div>
  <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger trim-edge-padding form-comp">
    <div *ngIf="email.errors.required">
      Email is required.
    </div>
    <div *ngIf="email.errors.minlength">
      Email must be at least 4 characters long.
    </div>
    <div *ngIf="email.errors?.pattern" class="text-danger form-comp">
      Please provide a valid email address
    </div>
  </div>
  <div *ngIf="message.invalid && (message.dirty || message.touched)" class="alert alert-danger trim-edge-padding form-comp">
    <div *ngIf="message.errors.required">
      Message is required.
    </div>
    <div *ngIf="message.errors.maxlength">
      Message must not be longer than 2000 characters.
    </div>
  </div>
  <div *ngIf="server_message" class="alert alert-primary trim-edge-padding form-comp">
    {{server_message}}
  </div>
  <div *ngIf="server_error" class="alert alert-danger trim-edge-padding form-comp">
    {{server_error}}
  </div>
</div>