import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { MybonsaiComponent } from './mybonsai/mybonsai.component';
import { FeaturesComponent } from './features/features.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { GardenFeatureComponent } from './components/garden-feature/garden-feature.component';
import { BonsaiFeatureComponent } from './components/bonsai-feature/bonsai-feature.component';
import { HistoryFeatureComponent } from './components/history-feature/history-feature.component';
import { TodoFeatureComponent } from './components/todo-feature/todo-feature.component';
import { GalleryFeatureComponent } from './components/gallery-feature/gallery-feature.component';
import { RecyclebinFeatureComponent } from './components/recyclebin-feature/recyclebin-feature.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { provideRoutes} from '@angular/router';
import { from } from 'rxjs';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'contact', component: ContactComponent },
  
  { path: 'features', component: FeaturesComponent, 
    children: [
        { path: '', component: GardenFeatureComponent },
        { path: 'garden-feature', component: GardenFeatureComponent },
        { path: 'bonsai-feature', component: BonsaiFeatureComponent },
        { path: 'history-feature', component: HistoryFeatureComponent },
        { path: 'todo-feature', component: TodoFeatureComponent },
        { path: 'gallery-feature', component: GalleryFeatureComponent },
        { path: 'recyclebin-feature', component: RecyclebinFeatureComponent }
    ] },
  { path: 'mybonsai', component: MybonsaiComponent },
  { path: 'terms' ,component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: '',   redirectTo: 'home', pathMatch: 'full' }, // redirect to `home-component`
  { path: '**', component: NotfoundComponent }//404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], 
  exports: [RouterModule], 
})
export class AppRoutingModule { }
