<h3 class="center-header">Todo</h3>
<p>
  Like any hobby, keeping Bonsai means that there is always something that needs
  doing, if not today, then perhaps tomorrow...
</p>
<p>
  My Bonsai Book allows you to setup Todos (tasks that requires to be executed
  on your Bonsai). These may be one-time or recurring tasks.
</p>
<p>
  When you have to do something periodically, you can setup a
  <strong>Repeat</strong> on the Todo. This repeat can be set to
  <strong>Daily</strong>, <strong>Weekly</strong>, <strong>Monthly</strong> or
  <strong>Yearly</strong>. Each of these repeat cycles allows you to configure
  when you want to get reminded to do something on the Bonsai or Garden. The app
  uses <i>Android's Notifications system</i> to remind you on the day for which you
  specified a Todo.
</p>
<p>
  Todo's can include descriptions and images that can help you with details.
  Especially useful when the Todo is setup for a few years into the future...
</p>

<hr />
<P>
  <!-- <sub>
    Coming Soon: The Todo Notifications feature curently only show that there
    are Todos. Soon we'll add another page on the App's Home screen that will
    allow you to see today's Todos at the top of the page so they will be easy
    to find!</sub> -->
</P>

<div style="height: 100px;"></div>
