<h3 class="center-header">Gardens</h3>
<p>
  In My Bonsai Book you can create Gardens. You can place any number of Bonsai
  into a Garden, and can have a Bonsai in any number of Gardens.
</p>

<p>
  Use Gardens to group trees with similar qualities together, like a "Deciduous"
  garden, or a "Seedlings" garden. Or group Bonsai that are placed in the same
  area in your actual garden together, like a "Sunny Deck" garden, or,
  "Backyard" garden.
</p>
<p>
  Why have Gardens?
</p>
<ul>
  <li>
    <p>
      It simplifies maintenance by grouping your Bonsai into Gardens to quickly
      and easily perform tasks on any number of Bonsai at the same time.
    </p>
  </li>
  <li>
    <p>
      When you add edit or remove Records in the History section of a Garden,
      all the Bonsai in the Garden will be updated.
    </p>
  </li>
  <li>
    <p>
      When you action a Todo in a Garden, all the Bonsai in the Garden is
      updated with a new Record.
    </p>
  </li>
</ul>

When you view your Garden in the App, you have 3 tabs with details:
<strong>BONSAI, HISTORY, TODOS</strong>. These are the maintainable aspects of
the Gardens and allows you to record and maintain your Gardens simply and
efficiently.
<ul>
  <li>
    <p>
      The <strong>BONSAI</strong> tab allows you to group Bonsai in the Garden.
    </p>
  </li>
  <li>
    <p>
      The <strong>HISTORY</strong> tab show you the Records added to the Garden.
    </p>
  </li>
  <li>
    <p>
      The <strong>TODOS</strong> tab shows a list of Todos that have been setup
      for the Garden.
    </p>
  </li>
</ul>
<hr />
<sub>
  Coming Soon: We will allow Gardens to be setup as Public or Private Gardens.
  This means we will be sharing your Bonsai here on My Bonsai Book, to the rest
  of the world. If you want your Bonsai to be seen, just set your Garden to be a
  Public Garden and all the Bonsai in that Garden will become available for
  viewing.
</sub>
<div style="height: 100px;"></div>
