<div class="col-md-6 offset-md-3 text-content">
  <h2>Terms and Conditions</h2>
  By accessing and using My Bonsai Book, you accept and agree to be bound by the
  terms and provision of this agreement.<br />
  In addition, when using the services, you shall be subject to any posted
  guidelines or rules applicable to such services.<br />
  Any participation in this service will constitute acceptance of this
  agreement.<br />

  <h2>Access</h2>
  My Bonsai Book makes use of Social Provider Logins, currently we use Facebook
  and Google as social login options,<br />
  as an alternative to the social logins, you may provide your details directly
  to create and access your account.<br />
  The My Bonsai Book mobile app is currently accessible only through downloading
  it from the Google Play store.<br />
  An account is required to be able to use the services.<br />

  <h2>Privacy</h2>

  We only use personal information to identify our clients.<br />
  Other information we keep is the content you create while using My Bonsai
  Book.<br />
  For more information about our Privacy Policy please see out Privacy Policy
  document.<br />
  We do our best to keep your location secure.<br />
  GPS information found on your photos are removed before the photos are
  uploaded. In some rare cases this process may not work as expected, and so we
  ask that if this is an issue for you,<br />
  please turn your camera's GPS settings off so that this will not become a
  problem in the future.

  <h2>Disclaimer</h2>

  We do not take any responsibility for any damages or losses caused by the use
  of My Bonsai Book.<br />
  We provide an informational service only and will do our best to ensure your
  information remains secure and uncompromised.<br />

  <h2>Intellectual property</h2>

  My Bonsai Book is an original creation/development.<br />
  Do not copy, reproduce, prepare transitional or derivative works of My Bonsai
  Book or any of its parts in any form without our express written consent.<br />

  <h2>Features, Suggestions, Enhancements etc.</h2>

  My Bonsai Book is a new app and we appreciate your feedback and
  suggestions.<br />
  You can provide feedback and or suggestions via the Contact page in
  www.mybonsaibook.com<br />
  We look forward to the journey ahead and working with you to make My Bonsai
  Book a great service!<br />
  However, we will not be able to provide any financial reward for feedback or
  suggestions.<br />

  <h2>Advertising</h2>

  The app shows advertising material.<br />
  In app advertising allows us with a revenue stream, while providing the
  service to you for free.<br />
  We do not own the advertising materials and are not directly involved in
  advertising content management and so,<br />
  take no responsibility for any offenses or damages caused by the advertising
  content.<br />

  <h2>Inappropriate Content</h2>

  My Bonsai Book is a service dedicated to Bonsai enthusiasts, we require that
  any and all content generated or uploaded via My Bonsai Book to be related to
  Bonsai.<br />
  If we happen to find inapprorpriate content on our server we reserve the right
  to block, disable or delete the account that contains the inapprorpriate
  content and/or the content itself, without notice or warning.<br />
  In extreme cases, under legal advise, we may notify authorities of such
  content and will hold the account-holder responsible for any
  transgressions.<br />

  <h2>Changes to this policy</h2>

  As a new and growing software service we will continually update this policy,
  so be sure to check it out every now and then.<br />
  At this stage we will not be informing you of any changes.<br /><br />
  But as a form of version control, we will make the date visible where you
  access this policy.<br />

  <p></p>
  <hr />
  MyBonsaiBook<br />
  26-November-2020
</div>
