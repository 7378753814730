<h3 class="center-header">History</h3>
<p>
  The History feature allows you to keep track of things you do on your Bonsai
  or Gardens and can also help you record other important things.
</p>
<p>
  It is entirely up to you to decide how granular and detailed you want to
  record your Bonsai! Some may only record big tasks like repotting, or complete
  defoliations etc. while others may whish to record more details and include
  more common tasks such as feeding or watering. - There is no right or wrong
  way!
</p>
<p>
  Historic records can be edited and changed. Do you want to add more detail or
  take a photo for a more detailed record? Do you recall that you did something
  to the tree a year ago? That's fine, all you need to do is set the Date for
  the History Record and hit save! The History list is sorted by the Record
  Date, so you will see the most recent Records at the top of the page.
</p>
<p>
  We have a number of predefined types of Historic Records that you can make use
  of - or ignore if you want to... When you create a new Record in the History
  tab of a Bonsai or a Garden, you can choose if the record is a:
</p>
<ul>
  <li>
    <P>
      <strong>Task</strong>: Something that you have done to the Bonsai: Repot,
      Defoliate, Styling etc.</P
    >
  </li>
  <li>
    <p>
      <strong>LifeCycle</strong> event, Record something about the Bonsai's
      natural evolution: Flowering, Budding, Fuiting etc.
    </p>
  </li>
  <li>
    <p>
      a <strong>Seasonal</strong> notice, Let's you record when the seasons
      impacts on the Bonsai: When Spring takes hold of your Bonsai, or when
      Winter's bite is felt.
    </p>
  </li>
  <li>
    <p>
      <strong>Environmental</strong> issue, Things that you need to watch out
      for due to environmental factors: Squirrel season? Alot of birds around
      all of a sudden? When you notice these thing impacting on your Bonsai,
      record it in My Bonsai Book. It could help you in the future to avoid
      damage to your Bonsai.
    </p>
  </li>
  <li>
    <p>
      <strong>Disease</strong> or <strong>Pesticide</strong> record. Recording
      these may help you prepare for bug season, or help you figure out why some
      Bonsai acquires rot or fungul diseases.
    </p>
  </li>
</ul>
<p>
  Of course these are just suggestions, you can also totally ignore these
  categories if you feel like it. In the future we may use these categories to
  provide a graphical timeline of your Bonsai, it would be nice to have good
  data to make for interesting graphs.
</p>
<hr />
<div style="height: 100px;"></div>
