<h3 class="center-header">Deleted Items</h3>
<p>
  When you delete something from My Bonsai Book (in the App), the deleted item will be sent
  to this Recycle bin. From this Deleted page you can decide to permanently
  delete it or recover the item. Note that when you delete something from the
  Delete screen, all records of it will be removed, even the image will be
  deleted.
</p>
<hr />
<div style="height: 100px;"></div>
