import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'mybonsaibook';
 

  constructor() {
    //private service: AppService) {
  }

  

  /*
  //function to resolve the reCaptcha and retrieve a token
  async resolved(captchaResponse: string, res) {
    console.log(`Resolved response token: ${captchaResponse}`);
    await sendTokenToBackend(capchaResponse); //declaring the token send function with a token parameter
  }

  //function to send the token to the node server
  sendTokenToBackend(tok) {
    //calling the service and passing the token to the service
    this.service.sendToken(tok).subscribe(
      data => {
        console.log(data)
      },
      err => {
        console.log(err)
      },
      () => { }
    );
  }
  */
}
